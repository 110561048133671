<template>
<div class=" block home">
<v-container>
    <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap" rel="stylesheet">  
    <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap" rel="stylesheet">
        <div class='homeTitle'>
          <h2 class='text-center'>{{ $t('options.title')}}</h2>           
        </div>
        <div>
          <p>{{ $t('options.intro')}}</p>
        </div>
        <v-row
        justify='center'
        align='center'
        >
          <v-col
          :cols = '12'
          :md = '6'
          align = 'center'>
            <div class='text-img-home'>
                <v-img
                :aspect-ratio="16/9"
                src="../assets/photos/homepage_1.jpg"
                >
                  <template v-slot:placeholder>
                      <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                      >
                      <v-progress-circular
                          indeterminate
                          color="amber"
                      ></v-progress-circular>
                      </v-row>
                  </template>                
                </v-img>
            </div>
          </v-col>
          <v-col :cols = '12'
          :md = '6'
          align = 'center'
          >
            <div>      
              <h4>{{ $t('options.firstp-title')}}</h4>
              {{ $t('options.firstp')}}
            </div>
          </v-col>
        </v-row>
        
        <v-row
        justify='center'
        align='center'
        class='text-img-home flex-column-reverse flex-md-row'
        >
          <v-col
          :cols = '12'
          :md = '6'
          align = 'center'
          >
            <div> 
              <h4>{{ $t('options.secondp-title')}}</h4>
              {{ $t('options.secondp')}}
            </div>          
          </v-col>        
          <v-col
          :cols = '12'
          :md = '6'
          >          
            <v-img
              :aspect-ratio="16/9"
              src="../assets/photos/homepage_2.jpg"
            >
              <template v-slot:placeholder>
                  <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                  >
                  <v-progress-circular
                      indeterminate
                      color="amber"
                  ></v-progress-circular>
                  </v-row>
              </template>           
            </v-img>
          </v-col>
        </v-row>

        <v-row
        justify='center'
        align='center'
        >
          <v-col
          :cols = '12'
          :md = '6'
          >
            <div class='text-img-home'>
              <v-img
                :aspect-ratio="16/9"
                src="../assets/photos/homepage_3.jpg"
              >
                <template v-slot:placeholder>
                    <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                    >
                    <v-progress-circular
                        indeterminate
                        color="amber"
                    ></v-progress-circular>
                    </v-row>
                </template>              
              </v-img>
            </div>
          </v-col>
          <v-col
          :cols = '12'
          :md = '6'
          align = 'center'>
              <div> 
                <h4>{{ $t('options.thirdp-title')}}</h4>
                {{ $t('options.thirdp')}}
                <p>{{ $t('options.conclusion')}}</p>
              </div>
          </v-col>
        </v-row>

</v-container>
</div>  
</template>

<script>
export default {
  name: 'Formules',
  components: {

  },
  data: () => ({
    alignments: [
        'start',
        'center',
        'end',
      ],
  })
}
</script>

<style scoped>
  .home {
      font-family: 'Raleway', sans-serif;
}

  .block {
  padding: 60px 0;
  border-bottom: 1px solid darken(white, 10%);
}

  .homeTitle {
    font-size: 30px;
    padding: 0 0 10px;
    margin: 0 0 40px;
    position: relative;
}
</style>
